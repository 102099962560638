import Form from "@rjsf/mui"
import validator from "@rjsf/validator-ajv8"
import type { ComponentProps, FC } from "react"
import { FieldTemplate } from "./FieldTemplate"
import { CheckboxWidget } from "./CheckboxWidget"
import { FormStyleHardOverwrites } from "./FormStyleHardOverwrites"
import { JsonFormThemeProvider } from "./JsonFormThemeProvider"
import { ObjectFieldTemplate } from "./ObjectFieldTemplate"
import { SelectWidget } from "./SelectWidget"

const prepareSchema = (schema: ComponentProps<typeof Form>["schema"]) => {
  return {
    ...schema,
    title: "",
    additionalProperties: true,
  }
}

export const JsonForm: FC<
  Omit<ComponentProps<typeof Form>, "schema" | "validator"> & {
    shouldRender?: boolean
    schema?: ComponentProps<typeof Form>["schema"]
  }
> = ({ shouldRender, liveValidate = true, schema, uiSchema, ...props }) => {
  return (
    <JsonFormThemeProvider>
      {schema && shouldRender && (
        <FormStyleHardOverwrites>
          <Form
            validator={validator}
            liveValidate={liveValidate}
            liveOmit={false}
            schema={prepareSchema(schema)}
            omitExtraData={false}
            templates={{ ObjectFieldTemplate, FieldTemplate }}
            widgets={{ CheckboxWidget, SelectWidget }}
            showErrorList="top"
            uiSchema={{
              "ui:options": {
                expandable: false,
                ...uiSchema?.["ui:options"],
              },
              ...uiSchema,
            }}
            {...props}
          />
        </FormStyleHardOverwrites>
      )}
    </JsonFormThemeProvider>
  )
}
