import { getAPIToken } from "context/auth/getAPIToken"
import type { AxiosResponse } from "axios"
import axios, { AxiosError } from "axios"

export const SESSION_EXPIRED_EVENT_NAME = "session-expired"

const is200Error = (
  response: AxiosResponse
): response is AxiosResponse<{
  ok?: boolean
  message?: string
  is_valid?: boolean
}> => {
  if (
    typeof response.data === "object" &&
    (("ok" in response.data && !response.data.ok) ||
      ("is_valid" in response.data && !response.data.is_valid))
  ) {
    return true
  }

  return false
}
const PaydaysApi = axios.create({
  baseURL: process.env.REACT_APP_PAYDAYS_API_URL,
})

PaydaysApi.interceptors.request.use(function (config) {
  if (config.includeToken) {
    const token = getAPIToken()

    if (!token) {
      throw new Error(
        `An authenticated call to ${config.url} was requested, but there is no token available.`
      )
    }

    config.headers ||= {}
    config.headers["Authorization"] = `Bearer ${token}`
  }

  return config
})

PaydaysApi.interceptors.response.use(
  function (response) {
    if (is200Error(response)) {
      const error = new AxiosError(response.data.message)

      error.response = response

      throw error
    }

    return response
  },
  function (error) {
    if (
      error.response?.status === 401 &&
      (error.response?.data?.msg === "Token has expired" ||
        error.response?.data?.message === "Token has expired")
    ) {
      const event = new CustomEvent(SESSION_EXPIRED_EVENT_NAME)
      window.dispatchEvent(event)
    }
    return Promise.reject(error)
  }
)

export default PaydaysApi
