import type { Schema } from "api"
import { isEmpty } from "lodash-es"

export const deepFilterSchema = (
  sourceSchema: Schema,
  search: string
): Schema | null => {
  if (sourceSchema.type !== "object") {
    return sourceSchema.description?.toLocaleLowerCase().includes(search) ||
      sourceSchema.title?.toLocaleLowerCase().includes(search)
      ? sourceSchema
      : null
  }

  if (
    sourceSchema.type === "object" &&
    sourceSchema.title?.toLocaleLowerCase().includes(search)
  ) {
    return sourceSchema
  }

  const entries = Object.entries(sourceSchema.properties || {})

  const onlyWithText = entries.reduce((prevEntries, [key, subSchema]) => {
    if (!JSON.stringify(subSchema).toLocaleLowerCase().includes(search)) {
      return prevEntries
    }

    const filteredSchema = deepFilterSchema(subSchema, search)

    if (
      !filteredSchema ||
      (filteredSchema.type === "object" && isEmpty(filteredSchema.properties))
    ) {
      return prevEntries
    }

    const entry: [string, Schema] = [key, filteredSchema]

    return [...prevEntries, entry]
  }, [] as typeof entries)

  return { ...sourceSchema, properties: Object.fromEntries(onlyWithText) }
}
